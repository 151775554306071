body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Main Content */
.main-content {
  background-color: #262626;

  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3); /* Stronger shadow for more depth */
  padding: 30px;
  margin-top: 20px;
  transition: all 0.4s ease-in-out;
}

.main-content:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 35px rgba(0, 0, 0, 0.4); /* Dynamic hover effect */
}

.airplane-icon {
  margin-bottom: 20px;
}

.main-title {
  color: #FFFFFF;
  font-size: 30px;
  font-weight: 600;
}

.main-description {
  font-size: 18px;
  color: #B0BEC5;
  margin-bottom: 30px;
}

/* Icon List Group */
.icon-list-group .list-item {
  background-color: #333333;
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 15px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}

.icon-list-group .list-item:hover {
  background-color: #3C3C3C;
  transform: translateY(-3px);
}

.icon-list-group .link-back {
  color: #BBDEFB; /* Softer blue for elegance */
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.icon-list-group .link-back:hover {
  color: #E3F2FD;
}

.icon {
  color: #29B6F6;
  font-size: 22px;
  margin-right: 12px;
}


.maa10 {
  
  margin-bottom: 120px;
}