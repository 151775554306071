/* Önerilen CSS Değişiklikleri */

/* Container düzeni için */
.data-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Ortala */
  align-items: center;
  margin: 0 auto; /* Ortala */
  padding: 16px; /* İçerik boşlukları */
  width: 100%; /* Tam genişlik */
  max-width: 1200px; /* Maksimum genişlik */
}

/* Box (Pozisyon) düzeni */
.data-box {
  width: 80px; /* Box genişliği */
  height: 80px; /* Box yüksekliği */
  margin: 4px; /* Kutular arası boşluk */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000; /* Çerçeve rengi */
  border-radius: 4px; /* Hafif köşe yuvarlama */
  font-size: 16px; /* Yazı boyutu */
  cursor: pointer;
  transition: all 0.3s ease-in-out; /* Hover animasyonu */
}

/* Responsive düzen */
@media (max-width: 768px) {
  .data-box {
    width: 60px; /* Mobilde daha küçük kutular */
    height: 60px;
    font-size: 14px; /* Yazı boyutunu küçült */
  }

  .data-container {
    padding: 8px; /* Mobilde daha az boşluk */
  }
}

/* Hover durumu */
.data-box:hover {
  background-color: #f0f0f0; /* Hover arka plan */
  transform: scale(1.05); /* Hafif büyüme */
}

/* Seçili kutu durumu */
.data-box.selected {
  background-color: #ffeb3b; /* Seçili kutu rengi */
  border-color: #ff9800; /* Seçili çerçeve rengi */
}

.dark-popup {
  border: 1px solid #444444; /* Popup çerçeve rengi */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Gölge efekti */
}